define("discourse/plugins/poll/discourse/components/poll-options", ["exports", "@glimmer/component", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/service", "@ember/template", "discourse/helpers/route-action", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _modifier, _object, _service, _template2, _routeAction, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PollOptionsComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    isChosen = option1 => {
      return this.args.votes.includes(option1.id);
    };
    sendClick(option1) {
      this.args.sendOptionSelect(option1);
    }
    static #_2 = (() => dt7948.n(this.prototype, "sendClick", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <ul>
          {{#each @options as |option|}}
            <li tabindex="0" data-poll-option-id={{option.id}}>
              {{#if this.currentUser}}
                <button {{on "click" (fn this.sendClick option)}}>
                  {{#if (this.isChosen option)}}
                    {{#if @isCheckbox}}
                      {{icon "far-check-square"}}
                    {{else}}
                      {{icon "circle"}}
                    {{/if}}
                  {{else}}
                    {{#if @isCheckbox}}
                      {{icon "far-square"}}
                    {{else}}
                      {{icon "far-circle"}}
                    {{/if}}
                  {{/if}}
                  <span class="option-text">{{htmlSafe option.html}}</span>
                </button>
              {{else}}
                <button onclick={{routeAction "showLogin"}}>
                  {{#if (this.isChosen option)}}
                    {{#if @isCheckbox}}
                      {{icon "far-check-square"}}
                    {{else}}
                      {{icon "circle"}}
                    {{/if}}
                  {{else}}
                    {{#if @isCheckbox}}
                      {{icon "far-square"}}
                    {{else}}
                      {{icon "far-circle"}}
                    {{/if}}
                  {{/if}}
                  <span class="option-text">{{htmlSafe option.html}}</span>
                </button>
              {{/if}}
            </li>
          {{/each}}
        </ul>
      
    */
    {
      "id": "gWK4ZczS",
      "block": "[[[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[14,\"tabindex\",\"0\"],[15,\"data-poll-option-id\",[30,2,[\"id\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"currentUser\"]],[[[1,\"            \"],[11,\"button\"],[4,[32,0],[\"click\",[28,[32,1],[[30,0,[\"sendClick\"]],[30,2]],null]],null],[12],[1,\"\\n\"],[41,[28,[30,0,[\"isChosen\"]],[[30,2]],null],[[[41,[30,3],[[[1,\"                  \"],[1,[28,[32,2],[\"far-check-square\"],null]],[1,\"\\n\"]],[]],[[[1,\"                  \"],[1,[28,[32,2],[\"circle\"],null]],[1,\"\\n\"]],[]]]],[]],[[[41,[30,3],[[[1,\"                  \"],[1,[28,[32,2],[\"far-square\"],null]],[1,\"\\n\"]],[]],[[[1,\"                  \"],[1,[28,[32,2],[\"far-circle\"],null]],[1,\"\\n\"]],[]]]],[]]],[1,\"              \"],[10,1],[14,0,\"option-text\"],[12],[1,[28,[32,3],[[30,2,[\"html\"]]],null]],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,\"button\"],[15,\"onclick\",[28,[32,4],[\"showLogin\"],null]],[12],[1,\"\\n\"],[41,[28,[30,0,[\"isChosen\"]],[[30,2]],null],[[[41,[30,3],[[[1,\"                  \"],[1,[28,[32,2],[\"far-check-square\"],null]],[1,\"\\n\"]],[]],[[[1,\"                  \"],[1,[28,[32,2],[\"circle\"],null]],[1,\"\\n\"]],[]]]],[]],[[[41,[30,3],[[[1,\"                  \"],[1,[28,[32,2],[\"far-square\"],null]],[1,\"\\n\"]],[]],[[[1,\"                  \"],[1,[28,[32,2],[\"far-circle\"],null]],[1,\"\\n\"]],[]]]],[]]],[1,\"              \"],[10,1],[14,0,\"option-text\"],[12],[1,[28,[32,3],[[30,2,[\"html\"]]],null]],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@options\",\"option\",\"@isCheckbox\"],false,[\"each\",\"-track-array\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/poll/discourse/components/poll-options.js",
      "scope": () => [_modifier.on, _helper.fn, _dIcon.default, _template2.htmlSafe, _routeAction.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PollOptionsComponent;
});